.Container {
    width: 100%;
    height: 62px;
    background-color: #002069;

    display: flex;
    justify-content: space-between;
    align-items: center;

    position: absolute;
    bottom: -3em;
    left: 0;
    right: 0;

    box-shadow: 0 -2px 4px rgba(182, 182, 182, 0.5);
}
.NextLabel {
    font-size: 18px;
    font-weight: bold;
    color: #ffffff;
    margin-right: 16px;
}
