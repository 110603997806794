@import-normalize;

.App {
  max-width: 64em;
  margin: 0 auto;
  color: #464649;
}

body {
  font-family: "Open Sans", sans-serif;
}

header {
  background: #fbfbfb;
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 2em 0 3em;

  .title {
    flex-grow: 1;
    margin: 0 2em 2em;
    width: 100%;
  }

  .img {
    width: calc(70% - 3em - 2em - 4px);
    height: 20em;
    margin-left: 2em;
    margin-right: 3em;
    border: 2px dashed #d1d1d6;
    background: #fff;
    align-items: center;
    justify-content: center;
    display: flex;

    img {
      width: 100%;
      max-height: 100%;
    }
  }

  .promos {
    width: calc(30% - 2em - 2em);
    margin: 0 auto;

    .promos__list {
      width: 100%;
      height: 100%;
      background: #ffffff;
      padding: 1em;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    div {
      margin-bottom: 0.5em;
      display: flex;
      flex-direction: column;

      label {
        line-height: 1.9em;
        span {
          font-size: 0.875em;
          b {
            font-size: 1.25em;
          }
        }
      }

      input[type="radio"] {
        border: 0px;
        width: 0.5em;
        height: 1em;
        vertical-align: top;
        font-size: 2em;
        margin-right: 0.2em;
      }

      .button-container {
        display: flex;
        flex-direction: row;
      }

      button {
        margin-top: 0.5em !important;
      }

      button.promo {
        font-size: 0.875em;
        width: 40%;
        margin: 0 auto;
        padding: 0.438em;
      }
    }
  }
}

section.main {
  width: calc(70% - 3em - 2em);
  padding-left: 2em;
  padding-right: 3em;
}

section.sidebar {
  width: calc(30% - 2em - 3em);
  padding-left: 3em;
  padding-right: 2em;
}

h1,
h2,
h3,
h4 {
  color: #000;
  font-family: "Raleway", sans-serif;
}

h2:after {
  content: "";
  display: block;
  margin: 0 0;
  width: 3em;
  padding-top: 0.5em;
  border-bottom: 3px solid #e1e8f7;
}

.preview {
  align-items: center;
}
.owner {
  display: flex;
  flex-direction: row;

  img,
  .img {
    width: 3em;
    height: 3em;
    border-radius: 50%;
    vertical-align: middle;
    border: 2px solid #e1e8f7;
    margin-right: 1em;
    position: relative;
    flex-shrink: 0;

    .button-plus {
      width: 100%;
      height: 100%;
      font-size: 2.4em;
      margin: 0 auto;
      text-align: center;
      display: block;
      cursor: pointer;
    }
  }
}

.supporting {
  list-style-type: square;
}

ul,
ol {
  margin-left: 0;
  padding-left: 0;
  list-style-position: inside;
}

.sidebar {
  ol {
    font-weight: bold;

    li {
      padding-bottom: 1.25em;

      span {
        font-weight: normal;
        display: block;
      }
    }
  }
}

input[type="text"],
input[type="password"],
input[type="number"],
input[type="email"],
input[type="tel"],
select,
textarea {
  width: auto;
  text-align: left;
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
  outline: 0;
  -webkit-appearance: none;
  line-height: 1.4285em;
  padding: 0.27857143em 0.8em;
  font-size: 1em;
  background: #fff;
  border: 1px solid #efeff4;
  border-radius: 0.28571429em;
  box-shadow: 0 0 0 0 transparent inset;
  -webkit-transition: color 0.1s, border-color 0.1s;
  transition: color 0.1s ease, border-color 0.1s ease;

  &:active,
  &:focus {
    border: 2px solid #2e4068;
    margin: -1px 0 0 -1px;
  }

  &::placeholder {
    color: #8e8e93;
  }
}

button,
.button {
  cursor: pointer;
  display: inline-block;
  font-size: 1em;
  outline: 0;
  border: none;
  vertical-align: baseline;
  background: #f6ab32;
  color: #ffffff;
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
  padding: 0.78571429em 1.5em;
  text-transform: none;
  text-shadow: none;
  font-weight: 700;
  line-height: 1em;
  font-style: normal;
  text-align: center;
  text-decoration: none;
  border-radius: 1.28571429em;
  position: relative;
  box-sizing: border-box;
}

.button--transparent {
  background: transparent;
  color: #f6ab32;
}

.input-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 0.875em;
}

.input__error {
  color: red;
  padding-top: 0.25em;
}

.input-array {
  .input-wrapper {
    width: calc(100% - 2.5em);
  }

  button.button--remove {
    font-weight: 800;
    margin: 0 0 0.5em 0.5em;
    padding: 0.278571em 0.71em;
    height: 2em;
  }
}

input[type="file"] {
  height: 0;
  overflow: hidden;
  width: 0 !important;
}

.promos-modal__submit {
  margin-top: 2em;
  margin-bottom: 1em;
  text-align: center;
}

.loading {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
