.Container {
    width: 100%;
    height: 110px;
    box-shadow: 0 2px 4px rgba(182, 182, 182, 0.5);
    background-color: #ffffff;
    margin-bottom: 2px;

    display: flex;
    justify-content: center;
}
.StepBoxContainer {
    width: 110px;
    height: 70px;
    margin: 20px 0 auto 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.StepBox {
    position: relative;
    display: flex;
    justify-content: center;
}
.StepNumber {
    width: 26px;
    height: 26px;
    border: 2px solid #2e4068;
    border-radius: 50%;
    display: inline-block;
    text-align: center;
    line-height: 1.5;
    margin-top: 10px;
    font-weight: 600;
    color: #2e4068;
}
.StepNumberActive {
    background: #002069;
    color: #fff;
}
.StepLabel {
    color: #002069;
    font-size: 12px;
    align-self: center;
}
.ConnectorRight {
    display: inline-block;
    height: 0;
    border-bottom: 1px solid #d8d8d8;
    position: absolute;
    top: 23px;
    left: 70px;
    width: 42px;
}
.ConnectorLeft {
    display: inline-block;
    height: 0;
    border-bottom: 1px solid #d8d8d8;
    position: absolute;
    top: 23px;
    right: 70px;
    width: 42px;
}
