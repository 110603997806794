
.Heading {
    font-size: 32px;
    font-weight: bold;
    color: #000000;
    width: 100%;
    display: inline-block;
    text-align: center;
    margin-top: 20px;
}

.SubHeading {
    font-size: 24px;
    font-weight: bold;
    color: #8e8e93;
    text-align: center;
    display: inline-block;
    width: 100%;
    margin-top: 52px;
}

.TextField {
    width: 100%;
    border-radius: 23px;
    border: solid 1px #979797;
    font-size: 18px;
    padding: 12px 26px;
}

.TextFieldWrapper {
    display: flex;
    position: relative;
    width: 100%;
    margin-top: 32px;
}

.CopyLabel {
    position: absolute;
    font-size: 18px;
    font-weight: bold;
    color: #007aff;
    right: 26px;
    top: 12px;
    cursor: pointer;
}

.CopyLabelClicked {
    opacity: 0.7;
}

.VisitDashboardLink {
    width: 100%;
    font-size: 18px;
    font-weight: bold;
    color: #8e8e93;
    display: inline-block;
    text-align: center;
    margin-top: 52px;
}

.TermsWrapper {
    display: flex;
    flex-direction: column;
}

.TermsLabel {
    margin-top: 32px;
}

.TermsInput {
    margin-right: 10px;
    height: 14px;
}
.Button {
    align-self: center;
    max-width: 200px;
    margin-top: 52px;
}
.ErrorMessage {
    color: red;
    font-weight: bold;
    margin: 16px auto 0 ;
}


